import { createClient } from "@supabase/supabase-js";
import embeddableStorage from "@obr/features/embed/embeddableStorage";
import { Database } from "./types/Database";
import { getAuthStorageKey } from "./getAuthStorageKey";

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL!;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_KEY!;

// When adding a custom domain to database it changed the storage key and logged users out.
// We need to migrate users back to the old token key and remove the new token in local storage
try {
  const key = "sb-data-auth-token";
  const sbToken = localStorage.getItem(key);
  if (sbToken) {
    localStorage.setItem(getAuthStorageKey(), sbToken);
    localStorage.removeItem(key);
  }
} catch {}

export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey, {
  auth: {
    storage: embeddableStorage,
    storageKey: getAuthStorageKey(),
  },
});
